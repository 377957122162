import { NewsDtoHandler } from "../../dtos/NewsDto";
import api from "../../services";
import { getHeaders } from "../auth";

export default function loadNews(news, setOneNews) {
  const newsId = news;
  api
    .get(`/noticias/id/${newsId}`, getHeaders())
    .then((response) => {
      setOneNews(NewsDtoHandler.getByDatabase(response.data[0]));
    })
    .catch(() => console.log("Erro ao carregar dados do usuário"));
}
