import { RefreshRounded } from "@mui/icons-material";
import "./App.css";
import Routes from "./routes";
import PullToRefresh from "react-simple-pull-to-refresh";

export default function App() {
  const reloadPage = () => {
    return new Promise<void>((resolve) => {
      window.location.reload();
      resolve(); // Resolving the promise immediately after reload
    });
  };

  return (
    <PullToRefresh
      resistance={2}
      onRefresh={() => reloadPage()}
      pullingContent={
        <div className="w-full h-full flex justify-center items-center text-white">
          <RefreshRounded fontSize="large" className="animate-spin" />
        </div>
      }
    >
      <Routes />
    </PullToRefresh>
  );
}
