import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./style.scss";
import globoRb from "../../assets/ic_globo_sf.png";
import { Button } from "@mui/material";

export default function Offline() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const navigate = useNavigate();

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (navigator.onLine) {
        navigate(`/home`);
        console.log("Status da internet: " + navigator.onLine);
      } else {
        navigate(`/offline`);
        console.log("Status da internet: " + navigator.onLine);
      }
    };
    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, []);

  function VerificaInternet() {
    window.Android.verificaConexao();
  }
  function AbreOfflineAndroid() {
    try {
      window.Android.showOffline();
    } catch (e) {
      console.log("error", e);
    }
  }
  function AbreOfflineIos() {
    try {
      window.webkit.messageHandlers.viewOffline.postMessage("Botão Offline");
    } catch (e) {
      console.log("error", e);
    }
  }
  function AbreOfflineHandler() {
    AbreOfflineAndroid();
    AbreOfflineIos();
  }
  return (
    <>
      <div>
        <div className="error-container">
          <img src={globoRb} alt="" />
        </div>
        <h1 onClick={AbreOfflineHandler}>Sem Internet!</h1>
        <p className="zoom-area">
          {" "}
          Verifique sua <b>conexão</b> e tente novamente!
        </p>
        <div className="link-container">
          <a className="more-link" onClick={VerificaInternet}>
            <b>TENTAR NOVAMENTE</b>
          </a>
        </div>
        <div className="link-container">
          <a className="more-link" onClick={AbreOfflineHandler}>
            <b>ACESSAR CONTEÚDO OFFLINE</b>
          </a>
        </div>
      </div>
    </>
  );
}
