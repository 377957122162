import api from "../../services";
import { getHeaders } from "../auth";

export default async function loadVideo(setPosts) {
  await api
    .get(`/videos/`, getHeaders())
    .then((res) => {
      setPosts(res.data);
    })
    .catch((e) => console.log("erro a carregar os videos:", e));
}
