import { Button } from "@mui/material";
import "./btnStyle.scss";

export default function TabButton({ children, handleNavigate }: any) {
  return (
    <Button
      className="button_animation"
      variant="contained"
      size="large"
      color="success"
      sx={{
        width: "100%",
        padding: "15px 22px",
        borderRadius: "8px",
        ":hover": { bgcolor: "#00113C" },
        background: "#69C462",
      }}
      disableElevation
      onClick={handleNavigate}
    >
      {children}
    </Button>
  );
}
