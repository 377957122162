import api from "../../services";
import { getHeaders } from "../auth";

export default async function getEpisodes(id, setLoading, setEpisodesData) {
  setLoading(true);

  await api
    .get(`/videos/series/${id}`, getHeaders())
    .then((response) => {
      console.log(response.data);
      setEpisodesData(response.data);
    })
    .finally(() => {
      setLoading(false);
    })
    .catch((e) => console.log("Erro ao buscar informações de episódios:", e));
}
