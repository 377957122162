import { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import Plan from "../../iconComponents/plan";
import EditPassword from "../../iconComponents/edit";
import EditAccount from "../../iconComponents/editAccount";
import Exit from "../../iconComponents/exit";
import Done from "../../iconComponents/done";
import Close from "../../iconComponents/close";
import Download from "../../iconComponents/download";
import "./style.scss";
import { PlanUserDto, PlanUserDtoHandler } from "../../dtos/PlanUserDto";
import api from "../../services";
import { UserDto, UserDtoHandler } from "../../dtos/UserDto";
import Nav from "../../components/Nav";
import { getHeaders, loadAuth, loadUser } from "../../utils/auth";
import { loadPlanUser } from "../../utils/api";
import { dayDate } from "../../utils";

export default function Account() {
  const navigate = useNavigate();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenPass, setIsOpenPass] = useState(false);
  const [modalIsOpenData, setIsOpenData] = useState(false);
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
    field: "",
  });
  const [validationOldPassword, setValidationOldPassword] = useState(true);
  const validationOldPasswordRef = useRef(validationOldPassword);
  const [oldPassword, setOldPassword] = useState({
    atualPassword: "",
    newPassword: "",
    showPassword: false,
  });
  const [planUser, setPlanUser] = useState<PlanUserDto>(
    PlanUserDtoHandler.getDefault()
  );
  const [userBd, setUser] = useState<UserDto>(UserDtoHandler.getDefault());

  //puxando dados da API
  useEffect(() => {
    loadAuth(handleAuth);
    loadUser(setUser);
    loadPlanUser(setPlanUser);
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (navigator.onLine) {
        navigate(`/home`);
        console.log("Status da internet: " + navigator.onLine);
      } else {
        navigate(`/offline`);
        console.log("Status da internet: " + navigator.onLine);
      }
    };
    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, []);

  //verificando a senha valida
  async function loadLogin(body: any) {
    await api
      .post("/clientes/login", body)
      .then((response) => {
        const statusPassword = response.data.error;
        validationOldPasswordRef.current = statusPassword;
        setValidationOldPassword(statusPassword);
        console.log(response.data.error);
        console.log(validationOldPasswordRef);
      })
      .catch((e) => console.log(e));
  }

  function openModal() {
    setIsOpen(true);
  }

  function openModalPass() {
    setIsOpenPass(true);
  }
  
  function openModalData() {
    setIsOpenData(true);
  }
  
  function closeModal(event: any) {
    event.preventDefault();
    setIsOpen(false);
    setIsOpenPass(false);
    setIsOpenData(false);
  }

  function handleValidate() {
    const emailValido =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!userBd.fullName)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo nome!",
        field: "fullName",
      });
    if (userBd.fullName.length < 8)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o nome completo!",
        field: "fullName",
      });
    if (!userBd.email)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo email!",
        field: "email",
      });
    if (!userBd.email.match(emailValido))
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher um email valido!",
        field: "email",
      });
    if (!userBd.phoneNumber)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo celular!",
        field: "phoneNumber",
      });
    if (!userBd.cep)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo CEP!",
        field: "phoneNumber",
      });
    return true;
  }

  function handleValidadePassword() {
    if (!oldPassword.atualPassword)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo senha!",
        field: "oldPassword",
      });
    if (!userBd.password)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo senha!",
        field: "password",
      });
    if (userBd.password.length < 8)
      return setStatus({
        type: "error",
        mensagem: "Necessário que a senha tenha no minimo 8 caracteres!",
        field: "password",
      });
    if (!oldPassword.newPassword)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo confirmar senha!",
        field: "newPassword",
      });
    if (userBd.password !== oldPassword.newPassword) {
      return setStatus({
        type: "error",
        mensagem: "Necessário que a senha seja igual!",
        field: "password",
      });
    }

    return true;
  }

  //validando o erro do campo
  function handleValidationOldPassword(auth: any) {
    if (auth)
      return setStatus({
        type: "error",
        mensagem: "A senha incorreta",
        field: "atualPassword",
      });

    return true;
  }

  function handleSubmit(event: any) {
    event && event.preventDefault();

    if (!handleValidate()) return;

    handleSave(userBd);
    setIsOpenData(false);
  }

  //mudando valores do input alterar dados
  function handleChange(key: string, value: any) {
    setUser({ ...userBd, [key]: value });
  }

  //mudando os valores do input do password atual
  function handleChangeOldPassword(key: string, value: any) {
    setOldPassword({ ...oldPassword, [key]: value });
  }

  function handleSave(userData: UserDto) {
    const data = UserDtoHandler.patch(userData);
    api
      .put("/clientes/cadastro", data[0], getHeaders())
      .then((res) => {
        setUser(UserDtoHandler.patch(res.data[0]));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  //verificando e manipulando password do user
  async function handlePassword() {
    const user = userBd.cpf;
    const password = oldPassword.atualPassword;
    const body = { user, password };

    console.log(body);

    await loadLogin(body);

    console.log(validationOldPasswordRef.current);
  }

  async function handleSubmitPassword(event: any) {
    event && event.preventDefault();

    if (!handleValidadePassword()) return;

    if (!handleValidationOldPassword(validationOldPasswordRef.current)) return;

    handleSavePassword(userBd);
    setIsOpenPass(false);
  }

  //enviando o newPassword para o banco
  async function handleSavePassword(userData: UserDto) {
    const data = UserDtoHandler.editPassword(userData);
    await api
      .put("/clientes/cadastro", data[0], getHeaders())
      .then((res) => {
        setUser(UserDtoHandler.editPassword(res.data[0]));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  //manipulando o resultado do get da Auth
  function handleAuth(auth: boolean) {
    if (!auth) return;

    localStorage.removeItem("userJWT");
    navigate("/");
  }

  //function para sair
  function handleLogout() {
    localStorage.removeItem("userJWT");
    navigate("/");
  }

  function AbreOfflineAndroid() {
    try {
      window.Android.showOffline();
    } catch (e) {
      console.log("error", e);
    }
  }

  function AbreOfflineIos() {
    try {
      window.webkit.messageHandlers.viewOffline.postMessage("Botão Offline");
    } catch (e) {
      console.log("error", e);
    }
  }

  function AbreOfflineHandler() {
    AbreOfflineAndroid();
    AbreOfflineIos();
  }

  return (
    <>
      <div className="containerBtn">
        <button
          className="backBtn"
          style={{
            border: "none",
          }}
          onClick={() => {
            navigate("/home");
          }}
        >
          Voltar
        </button>
      </div>
      <div className="container__account">
        <ul className="box_account">
          <li className="list_account">
            <Plan className="icon" onClick={openModal} />
            <p className="text_list" onClick={openModal}>
              Meu plano
            </p>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              ariaHideApp={false}
              contentLabel="Example modal"
              overlayClassName="modal-overlay"
              className="modal-content"
            >
              <h3 className="modal_title">Meu plano</h3>
              <hr className="line" />
              <span className="box-info-account">
                <p className="title_text">Nº do contrato:</p>
                <p className="text_modal">{planUser.contract}</p>
              </span>
              <span className="box-info-account">
                <p className="title_text">Plano:</p>
                <p className="text_modal">{planUser.planName}</p>
              </span>
              <span className="box-info-account">
                <p className="title_text">Valor:</p>
                <p className="text_modal">R$ {planUser.value}</p>
              </span>
              <span className="box-info-account">
                <p className="title_text">Data de Vencimento:</p>
                <p className="text_modal">
                  {dayDate(planUser.dueDay, "DD/MM/YYYY")}
                </p>
              </span>
              <span className="box-info-account">
                <p className="title_text">Situação do Contrato:</p>
                <p className="text_modal">{planUser.situation}</p>
              </span>

              <button onClick={closeModal} className="close-modal">
                <Done />
              </button>
            </Modal>
          </li>
          <li className="list_account">
            <EditPassword className="icon" onClick={openModalPass} />
            <p className="text_list" onClick={openModalPass}>
              Alterar senha
            </p>
            <Modal
              isOpen={modalIsOpenPass}
              onRequestClose={closeModal}
              ariaHideApp={false}
              contentLabel="Example modal"
              overlayClassName="modal-overlay"
              className="modal-content-pass"
            >
              {status.type === "error" ? (
                <p style={{ color: "#ff0000", marginTop: "16px" }}>
                  {status.mensagem}
                </p>
              ) : (
                ""
              )}
              <h3 className="modal_title">Alterar senha</h3>
              <hr className="line" />
              <span className="box-info-accountPass">
                <p className="title_text">Senha atual:</p>
                <TextField
                  className="label_account"
                  size="small"
                  type="password"
                  color={
                    status.field === "atualPassword" ? "warning" : "success"
                  }
                  focused={status.field === "atualPassword" ? true : false}
                  value={oldPassword.atualPassword}
                  onChange={(e) =>
                    handleChangeOldPassword("atualPassword", e.target.value)
                  }
                  onBlur={handlePassword}
                />
              </span>
              <span className="box-info-accountPass">
                <p className="title_text">Nova senha:</p>
                <TextField
                  className="label_account"
                  size="small"
                  type="password"
                  color={status.field === "newPassword" ? "warning" : "success"}
                  focused={status.field === "newPassword" ? true : false}
                  onChange={(e) =>
                    handleChangeOldPassword("newPassword", e.target.value)
                  }
                />
              </span>
              <span className="box-info-accountPass">
                <p className="title_text">Confirmar senha:</p>
                <TextField
                  className="label_account"
                  size="small"
                  type="password"
                  color={status.field === "password" ? "warning" : "success"}
                  focused={status.field === "password" ? true : false}
                  value={userBd.password}
                  onChange={(e) =>
                    handleChange("password", e.target.value || "")
                  }
                />
              </span>
              <button onClick={handleSubmitPassword} className="save_modal">
                Salvar
              </button>
              <button onClick={closeModal} className="close-modal-pass">
                <Close />
              </button>
            </Modal>
          </li>
          <li className="list_account">
            <EditAccount className="icon" onClick={openModalData} />
            <p className="text_list" onClick={openModalData}>
              Alterar dados
            </p>
            <Modal
              isOpen={modalIsOpenData}
              onRequestClose={closeModal}
              ariaHideApp={false}
              contentLabel="Example modal"
              overlayClassName="modal-overlay"
              className="modal-content-data"
            >
              {status.type === "error" ? (
                <p style={{ color: "#ff0000", marginTop: "16px" }}>
                  {status.mensagem}
                </p>
              ) : (
                ""
              )}
              <h3 className="modal_title">Informações Pessoais</h3>
              <hr className="line" />
              <span className="box-info-account">
                <p className="title_text_data">Nome:</p>
                <TextField
                  className="label_account"
                  size="small"
                  type="text"
                  color={status.field === "fullName" ? "warning" : "success"}
                  focused={status.field === "fullName" ? true : false}
                  value={userBd.fullName}
                  onChange={(e) =>
                    handleChange("fullName", e.target.value || "")
                  }
                />
              </span>
              <span className="box-info-account">
                <p className="title_text_data">CPF/CNPJ:</p>
                <p className="text_modal">{userBd.cpf}</p>
              </span>
              <span className="box-info-account">
                <p className="title_text_data">Email:</p>
                <TextField
                  className="label_account"
                  size="small"
                  color={status.field === "email" ? "warning" : "success"}
                  focused={status.field === "email" ? true : false}
                  value={userBd.email}
                  onChange={(e) => handleChange("email", e.target.value || "")}
                  type="email"
                />
              </span>
              <span className="box-info-account">
                <p className="title_text_data">Celular:</p>
                <TextField
                  className="label_account"
                  size="small"
                  type="text"
                  color={status.field === "phoneNumber" ? "warning" : "success"}
                  focused={status.field === "phoneNumber" ? true : false}
                  value={userBd.phoneNumber}
                  onChange={(e) =>
                    handleChange("phoneNumber", e.target.value || "")
                  }
                  inputProps={{ maxLength: 11 }}
                />
              </span>
              <span className="box-info-account">
                <p className="title_text_data">CEP:</p>
                <TextField
                  className="label_account"
                  size="small"
                  type="text"
                  color={status.field === "cep" ? "warning" : "success"}
                  focused={status.field === "cep" ? true : false}
                  value={userBd.cep}
                  onChange={(e) => handleChange("cep", e.target.value || "")}
                  inputProps={{ maxLength: 8 }}
                />
              </span>
              <span className="box-info-account">
                <p className="title_text_data">Cidade:</p>
                <TextField
                  className="label_account"
                  size="small"
                  type="text"
                  color={status.field === "city" ? "warning" : "success"}
                  focused={status.field === "city" ? true : false}
                  value={userBd.city}
                  onChange={(e) => handleChange("city", e.target.value || "")}
                />
              </span>
              <span className="box-info-account">
                <p className="title_text_data">Estado:</p>
                <TextField
                  className="label_account"
                  size="small"
                  type="text"
                  color={status.field === "state" ? "warning" : "success"}
                  focused={status.field === "state" ? true : false}
                  value={userBd.state}
                  onChange={(e) => handleChange("state", e.target.value || "")}
                />
              </span>
              <button onClick={handleSubmit} className="save_modal">
                Salvar
              </button>
              <button onClick={closeModal} className="close-modal-pass">
                <Close />
              </button>
            </Modal>
          </li>
          <li className="list_account" onClick={AbreOfflineHandler}>
            <Download className="icon" />
            <p className="text_list">Videos Offline</p>
          </li>
          <li className="list_account" onClick={handleLogout}>
            <Exit className="icon" />
            <p className="text_list">Sair</p>
          </li>
        </ul>
      </div>
      <Nav />
    </>
  );
}
