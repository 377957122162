import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "../../components/Nav";
import "./style.scss";
import { loadAuth, loadSituation } from "../../utils/auth";

export default function Price() {
  const navigate = useNavigate();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const token = localStorage.getItem("userJWT");

  //Tirando aspa da string do token
  const tokenJWT = token?.substring(1, token?.length - 1);
  //pegando o carregamento de dados
  useEffect(() => {
    loadAuth(handleAuth);
    loadSituation(handleSituation);
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (navigator.onLine) {
        navigate(`/home`);
        console.log("Status da internet: " + navigator.onLine);
      } else {
        navigate(`/offline`);
        console.log("Status da internet: " + navigator.onLine);
      }
    };
    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, []);

  //manipulando o resultado do get da Auth
  function handleAuth(auth: boolean) {
    if (!auth) return;

    localStorage.removeItem("userJWT");
    navigate("/");
  }

  function handleSituation(data?: any) {
    if (data.situation !== "Pago") {
      navigate("/contract");
    }
  }

  return (
    <>
      {/* <AccountButton /> */}
      <div
        className="contentPrice"
        style={{
          overflow: "hidden",
          height: `${window.document.body.scrollHeight}px`,
        }}
      >
        <iframe
          src={`https://ruralbusiness.com.br/rbvideo/agroresumo.asp?token=${tokenJWT}`}
          width={"100%"}
          frameBorder="no"
          height={`${window.document.body.scrollHeight + 80}px`}
          id="framePrice"
          className="framePrice"
        ></iframe>
      </div>
      <Nav />
    </>
  );
}
