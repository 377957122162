import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  SituationUserDto,
  SituationUserDtoHandler,
} from "../../dtos/SituationUserDto";
import { loadSituation } from "../../utils/auth";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/paymentError.json";

export default function Contract() {
  const navigate = useNavigate();
  const [situationUser, setSituationUser] = useState<SituationUserDto>(
    SituationUserDtoHandler.getDefault()
  );

  //pegando o carregamento de dados
  useEffect(() => {
    loadSituation(handleSituation);
  }, []);

  function handleSituation(data?: any) {
    setSituationUser(data);
  }

  function AbreLink(url: string) {
    //Chamada android
    try {
      window.Android.linkExterno(url);
    } catch (e) {
      console.log("error", e);
    }
    //Chamada ios
    try {
      window.webkit.messageHandlers.openLink.postMessage(
        "openLink" + encodeURI(url)
      );
    } catch (e) {
      console.log("error", e);
    }
  }

  //function para sair
  function handleLogout() {
    localStorage.removeItem("userJWT");
    navigate("/");
  }

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="flex flex-col">
      <div className="p-12 bg-[#252525] w-full h-1/3 flex justify-center items-center flex-col">
        <p className="text-3xl font-semibold text-zinc-100">Ops...</p>
        <div className="w-full max-w-52 -m-8">
          <Lottie options={defaultOptions} />
        </div>
        <p className="text-zinc-300 text-xl p-4">Sua assinatura expirou!</p>
      </div>
      <div className="p-6 flex flex-col gap-6">
        <p className="text-xl text-zinc-100">
          Não fique sem acesso, faça já a renovação da sua assinatura!
        </p>
        <div className="space-y-3">
          <p className="text-base text-zinc-300">
            Para realizar sua renovação saia do app e acesse via navigador o
            endereço{" "}
            <a
              onClick={() => AbreLink("https://rbvideo.com.br")}
              className="font-bold underline"
            >
              rbvideo.com.br
            </a>
          </p>
          <p className="text-base text-zinc-300">
            Ou entre em contato com nosso atendimento pelo número{" "}
            <p className="font-bold underline inline">(67) 98132-9074</p>
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-12 p-6">
        <Button
          variant="contained"
          color="success"
          onClick={() => navigate("/home")}
          sx={{ padding: 2, borderRadius: 3 }}
          disableElevation
        >
          Já Fiz o Pagamento
        </Button>
        <Button
          variant="text"
          sx={{ color: "#969696", mt: 1, textDecoration: "underline" }}
          disableElevation
          onClick={handleLogout}
        >
          Sair para o Login
        </Button>
      </div>
    </div>
  );
}
