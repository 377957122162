import { SituationUserDtoHandler } from "../../dtos/SituationUserDto";
import api from "../../services";
import getHeaders from "./getHeaders";

export default async function loadSituation(handleSituation) {
  await api
    .get("/contratos/situacao/", getHeaders())
    .then((response) => {
      handleSituation(SituationUserDtoHandler.getByDatabase(response.data[0]));
      console.log("usuário autenticado")
    })
    .catch((e) => console.log("Erro ao autenticar o usuário:", e));
}
