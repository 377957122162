import Download from "../../iconComponents/download";

export default function VideosOfflineButton({AbreOfflineHandler}: any) {
  return (
    <div className="account-container" onClick={AbreOfflineHandler}>
      <Download />
      <p style={{ fontSize: 12 }} className="nav_text">Offline</p>
    </div>
  );
}
