import * as React from 'react';

export default function Price(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" 
            height="48" 
            width="48"
            fill=''
            className=''
            {...props}
        >
            <path d="M13.5 29.9v-2.25H8v-3h11V19.4H9.55q-.6 0-1.075-.45T8 17.85v-8.2q0-.65.45-1.075.45-.425 1.05-.425h4V5.9h3v2.25H22v3H11v5.25h9.45q.65 0 1.1.425.45.425.45 1.125v8.2q0 .6-.425 1.05-.425.45-1.075.45h-4v2.25Zm14.4 12-8.5-8.5 2.1-2.1 6.4 6.4 12-12 2.1 2.1Z"/>
        </svg>
    )
}