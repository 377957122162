import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logoLogin from "../../assets/logoLogin.png";
import "./style.scss";
import { getUserToken, loadAuth } from "../../utils/auth";

const getTokenUrl = `${process.env.REACT_APP_API_RB}/clientes/login`;
const registerTokenAppUrl = `${process.env.REACT_APP_API_RB}/dashboard/token/register`;

async function loginUser(credentials: any) {
  //Adiciona token firebase do app
  if (localStorage.getItem("tokenAppAndroid") != null) {
    const tokenApp = localStorage.getItem("tokenAppAndroid");
    const dispositivo = "android";
    const cp = credentials.user;
    registerToken(tokenApp, dispositivo, cp);
    console.log(tokenApp + " " + dispositivo + " " + cp);
  } else if (localStorage.getItem("tokenAppIos") != null) {
    const tokenApp = localStorage.getItem("tokenAppIos");
    const dispositivo = "ios";
    const cp = credentials.user;
    registerToken(tokenApp, dispositivo, cp);
    console.log(tokenApp + " " + dispositivo + " " + cp);
  }
  // faz login
  return await fetch(getTokenUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}
async function registerToken(
  tokenApp: string | null,
  dispositivo: string,
  cp: string
) {
  fetch(registerTokenAppUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ cp: cp, dispositivo: dispositivo, token: tokenApp }),
  }).then((data) => data.json());
}
export default function Login() {
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });

  useEffect(() => {
    handleSavePassword();
    //Se logado direciona para home
    loadAuth(handleAuth);
  }, []);

  function handleValidation() {
    if (!user)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo CPF!",
      });
    if (!password)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo Senha!",
      });
    return true;
  }
  //manipulando o resultado do get da Auth
  function handleAuth(auth: boolean) {
    if (!auth) {
      navigate("/home");
    } else {
      localStorage.removeItem("userJWT");
      navigate("/login");
    }
  }

  async function handleLogin(e: any) {
    e.preventDefault();
    if (!handleValidation()) return;

    const login = { user, password };

    const response = await loginUser({ ...login });

    if (response.error === true)
      return setStatus({ type: "error", mensagem: `${response.message}` });

    localStorage.setItem("userJWT", JSON.stringify(response.token));

    localStorage.setItem("userCPF", user);
    localStorage.setItem("password", password);
    handleSavePassword();

    navigate(`/token/${getUserToken()}`);
  }

  function handleChangeUser(e: any) {
    setUser(e.replace(/[^0-9]/g, ""));
    //setUser(e);
  }

  function handleChangePassword(e: any) {
    setPassword(e);
  }
  
  function handleSavePassword() {
    if (!localStorage.getItem("userCPF")) return;

    const user = String(localStorage.getItem("userCPF"));
    const password = String(localStorage.getItem("password"));

    setUser(user);
    setPassword(password);
  }

  return (
    <>
      <div className="container__login">
        <div className="content__login">
          <img src={logoLogin} alt="" className="img_logo" />
        </div>
        <form className="box_login">
          {status.type === "error" ? (
            <p className="errorType">{status.mensagem}</p>
          ) : (
            ""
          )}
          <p className="text_login">CPF</p>
          <TextField
            className="label_login email"
            size="small"
            color="success"
            autoFocus
            type="tel"
            value={user}
            onChange={(e) => {
              handleChangeUser(e.target.value);
            }}
            inputProps={{ maxLength: 14 }}
          />
          <p className="text_login">Senha</p>
          <TextField
            className="label_login senha"
            size="small"
            color="success"
            type={"password"}
            value={password}
            onChange={(e) => {
              handleChangePassword(e.target.value);
            }}
          />
          <button className="button_login" type="submit" onClick={handleLogin}>
            Entrar
          </button>
          <p
            className="forgotPass"
            onClick={() => navigate("/recuperar")}
            style={{ cursor: "pointer" }}
          >
            Esqueceu a senha?
          </p>
        </form>
      </div>
    </>
  );
}
