import getUserToken from "./getUserToken";

export default function getHeaders() {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${getUserToken()}`,
    },
  };

  return headers;
}
