import api from "../../services";
import getHeaders from "./getHeaders";

export default async function loadAuth(handleAuth) {
  api
    .get("/clientes/logado", getHeaders())
    .then((response) => {
      handleAuth(response.data.error);
    })
    .catch((e) => console.log("Erro ao autenticar o usuário:", e));
}
