import api from "../../services";
import { getHeaders } from "../auth";

export default async function loadFilterPlanUser(
  setMarketUser,
  isMarketClientRef
) {
  if (isMarketClientRef.current === -1)
    return setMarketUser([
      {
        nome: "Soja",
        id: 1,
      },
      {
        nome: "Milho",
        id: 2,
      },
      {
        nome: "Gado",
        id: 3,
      },
    ]);
  await api
    .get(`/videos/filtro/`, getHeaders())
    .then((res) => {
      const newFilterMarket = res.data;
      setMarketUser(newFilterMarket);
    })
    .catch((e) => console.log("Erro ao carregar videos:", e));
}
