export interface UserForgot  {
  cpf: string;
}

export class UserForgotHandler {
public static getDefault(data: any = {}): UserForgot {
  return {
    cpf: data.cpf || '',
  };
}

public static toDatabase(user: UserForgot): any {
  return [{
    cli_cpf_cnpj: user.cpf,
    }];
  }
}
 