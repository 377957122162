import { UserDtoHandler } from "../../dtos/UserDto";
import api from "../../services";
import getHeaders from "./getHeaders";

export default async function loadUser(setUser) {
  await api
    .get("/clientes/cadastro", getHeaders())
    .then((response) => {
      setUser(UserDtoHandler.getByDatabase(response.data[0]));
    })
    .catch((e) => console.log("Erro ao carregar dados do usuário:", e));
}
