import { getUserToken } from "../auth";

export default async function loadNoticias() {
  const getTokenUrl = `${process.env.REACT_APP_API_RB}/noticias/listar`;
  let noticiasAPI = [];

  try {
    const response = await fetch(getTokenUrl, {
      method: "GET",
      headers: {
        "x-access-token": `${getUserToken()}`,
      },
    });
    noticiasAPI = await response.json();
  } catch (error) {
    console.log(error);
  }

  return noticiasAPI;
};
