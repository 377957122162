import { useLocation, useNavigate } from "react-router-dom";
import Account from "../../iconComponents/person";
import "./style.scss";
import { Box } from "@mui/material";

export default function AccountButton() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // className={pathname === "/account" ? "icon_active" : "icon"}
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"fit-content"}
      onClick={() => navigate("/account")}
    >
      <Account className={pathname === "/account" ? "icon_active" : "icon"} />
      <p
        style={{ fontSize: 12 }}
        className={pathname === "/account" ? "nav_text_active" : "nav_text"}
      >
        Conta
      </p>
    </Box>
  );
}
