import Pagination from "@mui/material/Pagination";
import { useEffect, useState } from "react";
import NewsComponent from "../../components/NewsComponent";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import Nav from "../../components/Nav";
import scrollToTop from "../../utils/scrollToTop";
import { loadAuth, loadSituation } from "../../utils/auth";
import { loadNoticias } from "../../utils/api";

export default function News() {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);

  //pegando o carregamento de dados
  useEffect(() => {
    loadAuth(handleAuth);
    loadSituation(handleSituation);
    const fetchPosts = async () => {
      setLoading(true);
      const res = await loadNoticias();
      setPosts(res);
      setLoading(false);
    };
    fetchPosts();
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [currentPage, posts, loading]);

  //Obtendo os posts atuais por pages
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  //mudando as pages
  function paginate(pageNumber: any): void {
    return setCurrentPage(pageNumber);
  }

  //manipulando o resultado do get da Auth
  function handleAuth(auth: boolean) {
    if (!auth) return;

    localStorage.removeItem("userJWT");
    navigate("/");
  }

  function handleSituation(data?: any) {
    if (data.situation !== "Pago") {
      navigate("/contract");
    }
  }

  return (
    <>
      {/* <AccountButton /> */}
      <div className="container__home">
        <div className="content__home">
          <div className="conteiner_tabs"></div>

          <div className="container__videos">
            <NewsComponent posts={currentPosts} loading={loading} />
          </div>

          <div className="container_navigation">
            <Pagination
              count={Math.ceil(posts.length / postsPerPage)}
              color="primary"
              shape="rounded"
              showFirstButton={true}
              showLastButton={true}
              hideNextButton={true}
              hidePrevButton={true}
              onChange={(e, value) => paginate(value)}
              className="pagination"
            />
          </div>
        </div>
      </div>
      <Nav />
    </>
  );
}
