import api from "../../services";
import { getHeaders } from "../auth";

export default async function getSeries(setSeriesData, getEpisodes) {
  api
    .get("/videos/series/", getHeaders())
    .then((response) => {
      setSeriesData(response.data.reverse());
      getEpisodes(response.data[0].serie_id);
    })
    .catch((e) => console.log("Erro ao buscar informações de series:", e));
}
