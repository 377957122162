import * as React from 'react';

export default function editAccount(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            height="48" 
            width="48"
            className=''
            {...props}
        >
            <path d="M20 23.75q-3.3 0-5.4-2.1-2.1-2.1-2.1-5.4 0-3.3 2.1-5.4 2.1-2.1 5.4-2.1 3.3 0 5.4 2.1 2.1 2.1 2.1 5.4 0 3.3-2.1 5.4-2.1 2.1-5.4 2.1ZM4 39.8v-4.7q0-1.75.875-3.15T7.4 29.8q3.6-1.6 6.675-2.3 3.075-.7 5.925-.7h1.15q-.3.7-.45 1.375-.15.675-.25 1.625H20q-2.9 0-5.675.625T8.6 32.5q-.8.4-1.2 1.125Q7 34.35 7 35.1v1.7h13.45q.25.9.6 1.625t.85 1.375ZM33.35 42l-.5-3.3q-.85-.25-1.725-.725-.875-.475-1.475-1.075l-2.75.6-1.25-2.1L28 33.2q-.1-.45-.1-1.25t.1-1.25l-2.35-2.2 1.25-2.1 2.75.6q.6-.6 1.475-1.075.875-.475 1.725-.725l.5-3.3h2.7l.5 3.3q.85.25 1.725.725.875.475 1.475 1.075l2.75-.6 1.25 2.1-2.35 2.2q.1.45.1 1.25t-.1 1.25l2.35 2.2-1.25 2.1-2.75-.6q-.6.6-1.475 1.075-.875.475-1.725.725l-.5 3.3Zm1.35-6.05q1.8 0 2.9-1.1 1.1-1.1 1.1-2.9 0-1.8-1.1-2.9-1.1-1.1-2.9-1.1-1.8 0-2.9 1.1-1.1 1.1-1.1 2.9 0 1.8 1.1 2.9 1.1 1.1 2.9 1.1ZM20 20.75q1.95 0 3.225-1.275Q24.5 18.2 24.5 16.25q0-1.95-1.275-3.225Q21.95 11.75 20 11.75q-1.95 0-3.225 1.275Q15.5 14.3 15.5 16.25q0 1.95 1.275 3.225Q18.05 20.75 20 20.75Zm0-4.5Zm.45 20.55Z"/>
        </svg>
    )
}