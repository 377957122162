import api from "../services";
import { getHeaders } from "./auth";

export default async function canSeeAlert(setIsUserAllowed: any) {
  api
    .get("/contratos/situacao/", getHeaders())
    .then((response) => {
      response.data[0].cont_mercado !== 13
        ? setIsUserAllowed(false)
        : setIsUserAllowed(true);
    })
    .catch(() => console.log("Erro ao autenticar o usuário"));
}
