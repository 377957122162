import { Routes, Route } from "react-router-dom";

import Account from "./pages/Account";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Price from "./pages/Price";
import Movie from "./pages/Movie";
import Graphic from "./pages/Graphic";
import News from "./pages/News";
import OneNews from "./components/OneNews";
import InnerContent from "./components/InnerContent";
import ProtectedRoute from "./components/ProtectedRoute";
import Contract from "./pages/Contract";
import TokenLogin from "./pages/TokenLogin";
import Offline from "./pages/Offline";
import RecuperarSenha from "./pages/RecuperarSenha";

export default function RoutesComponents() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/recuperar" element={<RecuperarSenha />} />
      <Route path="/token/:token" element={<TokenLogin />} />

      {/* Rotas protegidas */}
      <Route path="/" element={<ProtectedRoute />}>
        <Route path="/" element={<InnerContent />}>
          <Route path="/home" element={<Home />} />
          <Route path="/contract" element={<Contract />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:news" element={<OneNews />} />
          <Route path="/account" element={<Account />} />
          <Route path="/price" element={<Price />} />
          <Route path="/movie" element={<Movie />} />
          <Route path="/graphic" element={<Graphic />} />
          <Route path="/offline" element={<Offline />} />
        </Route>
      </Route>
    </Routes>
  );
}
