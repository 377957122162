import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { NewsDto, NewsDtoHandler } from "../../dtos/NewsDto";
import "./style.scss";
import canSeeAlert from "../../utils/canSeeAlert";
import TabButton from "../Plans/TabButton";
import { dayDate } from "../../utils";
import { loadNews } from "../../utils/api";
import TextToSpeech from "../TextToSpeech/TextToSpeech";

export default function OneNews() {
  const navigate = useNavigate();
  const { news } = useParams();
  const [oneNews, setOneNews] = useState<NewsDto>(NewsDtoHandler.getDefault());
  const [isUserAllowed, setIsUserAllowed] = useState<Boolean>(true);

  //puxando dados da API
  useEffect(() => {
    loadNews(news, setOneNews);
    canSeeAlert(setIsUserAllowed);
  }, []);

  function handleNavigate() {
    return navigate(`/news`);
  }

  return (
    <>
      <div className="box_news">
        {!isUserAllowed ? (
          <>
            <div id="insert" className="modal">
              <div className="modal_news-content">
                <span className="modal-title-container">
                  <span className="modal-title">
                    Oops! Parece que você não pode ver este Alerta... Assine o
                    Plano TOP+ para ter acesso aos Alertas diários da Rural
                    Business
                  </span>
                  <span className="modal-text">
                    Acesse rbvideo.com.br para mudar o seu plano!
                  </span>
                </span>
                <form className="modal-form">
                  <TabButton handleNavigate={handleNavigate}>Voltar</TabButton>
                </form>
              </div>
            </div>
            <div className={`${isUserAllowed ? "none" : "news_blur"}`}>
              <h3 className="newsTitle">{oneNews.title}</h3>
              <div className="container-news_date">
                <p className="date_news">{dayDate(oneNews.date, "llll")}</p>
              </div>
              <h4 className="textNews">
                <div
                  id="terms-content"
                  dangerouslySetInnerHTML={{
                    __html: oneNews.text.split("\n")[0],
                  }}
                />
              </h4>
              <Link to={"/news"} className="textLink">
                <p className="veja__mais">&larr; Voltar para notícias</p>
              </Link>
            </div>
          </>
        ) : (
          <div className={`${isUserAllowed ? "none" : "news_blur"}`}>
            <h3 className="newsTitle">{oneNews.title}</h3>
            <div className="container-news_date">
              <p className="date_news">{dayDate(oneNews.date, "llll")}</p>
            </div>
            <h4 className="textNews">
              <div
                id="terms-content"
                dangerouslySetInnerHTML={{ __html: oneNews.text }}
              />
            </h4>
            <Link to={"/news"} className="textLink">
              <p className="veja__mais">&larr; Voltar para notícias</p>
            </Link>
          </div>
        )}
      </div>

      <TextToSpeech html={oneNews.text} />
    </>
  );
}
