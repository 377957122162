import * as React from 'react';

export default function Download(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" 
        height="35" 
        width="35"
        viewBox='0 0 48 48'
        fill='969696'
        {...props}
        >
        <path fill='#969696' d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"/>
    </svg>
    )
}