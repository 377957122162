import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  TextField,
  Box,
  Alert,
  InputAdornment,
  IconButton,
  Typography,
  Snackbar,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { UserForgot, UserForgotHandler } from "../../interfaces/userForgot";
import Logo from "../../assets/logoLogin.png";
import { sendEmail } from "../../utils/api";

export default function RecuperarSenha() {
  const navigate = useNavigate();
  const [userCpf, setUserCpf] = useState<UserForgot>(
    UserForgotHandler.getDefault()
  );
  const [open, setOpen] = useState(false);

  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });

  function handleClose(event?: React.SyntheticEvent | Event, reason?: string) {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  }

  function handleChangeUser(key: string, value: any) {
    setUserCpf({ ...userCpf, [key]: value });
  }

  function handleValidate() {
    if (!userCpf.cpf)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo CPF!",
      });
    if (userCpf.cpf.length < 11)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o CPF correto!",
      });

    return true;
  }

  function handleSubmit(e: any) {
    e.preventDefault();

    const user = UserForgotHandler.toDatabase(userCpf);

    if (!handleValidate()) return;

    sendEmail(user[0]);
    setOpen(true);
  }

  return (
    <>
      <Grid container justifyContent={"center"} alignContent={"center"}>
        <Grid item>
          <img
            src={Logo}
            className="img_logo"
            onClick={() => navigate("/login")}
          />
        </Grid>

        <Grid item xs={12} mt={12} p={2}>
          {status.type === "error" ? (
            <Alert
              variant="filled"
              severity="error"
              sx={{
                mb: 1,
                maxWidth: "320px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              className={
                status.type === "error" ? "errorAssinatura" : "div-OFF"
              }
            >
              {status.mensagem}
            </Alert>
          ) : (
            ""
          )}
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
              backgroundColor: "#444444",
              borderRadius: "15px",
              width: "100%",
            }}
            p={2}
          >
            <Typography variant="h6" fontWeight={700} color={"lightgreen"}>
              Esqueceu a senha?
            </Typography>
            <hr />
            <Typography variant="body1" fontWeight={700} mt={1} mb={1}>
              Digite o CPF da sua conta de usuário
            </Typography>
            <Typography variant="subtitle1">
              Nós lhe enviaremos um e-mail com a sua senha.
            </Typography>
            <TextField
              size="small"
              type={"email"}
              placeholder="CPF"
              color="success"
              required
              fullWidth
              value={userCpf.cpf}
              sx={{
                backgroundColor: "white",
                borderRadius: "4px",
                mb: 1,
                mt: 1,
              }}
              onChange={(e) => {
                handleChangeUser("cpf", e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      edge="start"
                      sx={{ cursor: "default" }}
                      className="icon-btn"
                    >
                      <AccountCircle />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="success"
              type="submit"
              fullWidth
              onClick={handleSubmit}
            >
              Enviar email com a senha
            </Button>
          </Box>
        </Grid>

        <Button
          variant="text"
          type="submit"
          onClick={() => navigate("/login")}
          sx={{ mt: 2, color: "#969696" }}
        >
          Voltar para o login
        </Button>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Senha enviado no e-mail.
        </Alert>
      </Snackbar>
    </>
  );
}
