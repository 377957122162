import Vimeo from "@u-wave/react-vimeo";
import icoOffline from "../../assets/icoOffline.png";
import preLoader from "../../assets/loader.gif";
import "./style.scss";
import { dayDate, hourDate } from "../../utils";
import { Box } from "@mui/material";

export default function video({ posts, loading }: any) {
  if (loading) {
    return (
      <div className="box_video">
        <img className="preLoader" src={preLoader} />
      </div>
    );
  }

  function newDate(date: any) {
    return `${dayDate(date, "dddd")}, ${dayDate(date, "LL")} (${hourDate(
      date
    )})`;
  }

  function newVideo(date: any) {
    const newDate = new Date();
    const dateDay = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

    const today = `${dateDay}/${month}/${year}`;

    if (today === dayDate(date, "l")) return "Novo";

    return "";
  }

  function marketplace(data: any) {
    if (data === 1) return "Análise de mercado de Boi";
    if (data === 2) return "Análise de mercado de Soja";
    if (data === 3) return "Análise de mercado de Milho";
    if (data === 8) return "Análise de mercado de Leite";
    if (data === 7) return "Análise de mercado de Frango";
    if (data === 9) return "Análise de mercado de Suínos";
    if (data === 5) return "Análise de mercado de Trigo";
    if (data === 10) return "Alerta de mercado";
  }

  const downloadLink = (file: string) => {
    try {
      window.Android.baixarVideo(file);
    } catch (e) {
      console.log("error", e);
    }
    try {
      window.webkit.messageHandlers.downVideo.postMessage(
        "itemDownload" + encodeURI(file)
      );
    } catch (e) {
      console.log("error", e);
    }
  };

  return (
    <>
      {posts.map((post: any) => (
        <div className="box_video">
          <p className="newVideo">{newVideo(post.vid_data)}</p>
          <div className="box_descricao">
            <div>
              <div className="box_type">
                <p className="type_video">{marketplace(post.vid_mercado)}</p>
              </div>
              <p className="date_video">{newDate(post.vid_data)}</p>
            </div>
            <a
              className="btnOffline"
              onClick={() => {
                downloadLink(post.vid_arquivo);
              }}
            >
              <img src={icoOffline} width="35px" />
            </a>
          </div>
          <div className="videoIframe">
            {!post.vid_cdn && (
              <Box
                component={"iframe"}
                src={`https://player.vimeo.com/video/${post.vid_end}?h=${post.vid_hash}`}
                width={"100%"}
                allow="autoplay; fullscreen; picture-in-picture"
                border={"none"}
              />
            )}
            {post.vid_cdn && (
              <iframe
                src={post.vid_cdn}//+ "&preload=false"
                width={"100%"}
                allow="fullscreen; accelerometer; gyroscope; autoplay ;picture-in-picture"
                allowFullScreen
                />
              /*<Box
                component={"iframe"}
                src={post.vid_cdn}
                width={"100%"}
                allow="fullscreen; accelerometer; gyroscope; autoplay ;picture-in-picture"
                border={"none"}
              />*/
            )}
          </div>
          <h3 className="video_title">{post.vid_titulo}</h3>
        </div>
      ))}
    </>
  );
}
