import { PlanUserDtoHandler } from "../../dtos/PlanUserDto";
import api from "../../services";
import { getHeaders } from "../auth";

export default function loadPlanUser(setPlanUser) {
  api
    .get("/contratos/situacao/", getHeaders())
    .then((response) => {
      setPlanUser(PlanUserDtoHandler.getByDataBase(response.data[0]));
    })
    .catch((e) => console.log("Erro ao carregar planos do usuário:", e));
}
