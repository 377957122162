export interface UserDto {
  id?: number;
  fullName?: string;
  phoneNumber?: string;
  address?: string;
  addressNumber?: string;
  birthday?: string;
  neighboardhood?: string;
  city?: string;
  state?: string;
  cep?: string;
  email?: string;
  password?: string;
  cpf?: string;
  createdAt?: string;
  updatedAt?: string;
};
  
  export class UserDtoHandler {
    public static getDefault(data: any = {}): UserDto {
      return {
        id: data.id || null,
        fullName: data.fullName || '',
        phoneNumber: data.phoneNumber || '',
        address: data.address || '',
        addressNumber: data.addressNumber || '',
        birthday: data.birthday || '',
        neighboardhood: data.neighboardhood || '',
        city: data.city || '',
        state: data.state || '',
        cep: data.cep || '',
        email: data.email || '',
        password: data.password || '',
        cpf: data.cpf || '',
        createdAt: data.createdAt || '',
        updatedAt: data.updatedAt || '',
      };
    }
  
    public static getByDatabase(data: any = {}): UserDto {
      return this.getDefault({
        id: data.cli_id,
        fullName: data.cli_nome,
        phoneNumber: data.cli_telefone,
        address: data.cli_rua,
        addressNumber: data.cli_numero,
        birthday: data.cli_data_nasc,
        neighboardhood: data.cli_bairro,
        city: data.cli_cidade,
        state: data.cli_estado,
        cep: data.cli_cep,
        email: data.cli_email,
        password: data.cli_senha,
        cpf: data.cli_cpf_cnpj,
        createdAt: data.cli_data_cadastro,
        updatedAt: data.cli_data_modific,
      });
    }
  
    public static toDatabase(user: UserDto): any {
      return [{
        cli_id: user.id,
        cli_nome: user.fullName,
        cli_telefone: user.phoneNumber,
        cli_rua: user.address,
        cli_numero: user.addressNumber,
        cli_data_nasc: user.birthday,
        cli_bairro: user.neighboardhood,
        cli_cidade: user.city,
        cli_estado: user.state,
        cli_cep: user.cep,
        cli_email: user.email,
        cli_senha: user.password,
        cli_cpf_cnpj: user.cpf,
        cli_data_cadastro: user.createdAt,
        cli_data_modific: user.updatedAt,
      }];
    }
  
    public static patch(user: UserDto): any {
      return [{
        cli_id: user.id,
        cli_nome: user.fullName,
        cli_telefone: user.phoneNumber,
        cli_cep: user.cep,
        cli_cidade: user.city,
        cli_email: user.email
      }];
    }

    public static editPassword(user: UserDto): any {
      return [{
        cli_senha: user.password,
      }];
    }
  };
