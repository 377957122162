import api from "../../services";

export default async function sendEmail(user) {
  try {
    await api.post("/clientes/recuperar", user);
  } catch (e) {
    console.log("Erro ao tentar salvar o usuário:", e);
    return;
  }
}
