import * as React from 'react';

export default function News(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            height="48" 
            width="48"
            className=''
            {...props}
        >
            <path d="M7 42q-1.25 0-2.125-.875T4 39V6l3.35 3.35L10.65 6 14 9.35 17.35 6l3.3 3.35L24 6l3.35 3.35L30.65 6 34 9.35 37.35 6l3.3 3.35L44 6v33q0 1.25-.875 2.125T41 42Zm0-3h15.5V25H7v14Zm18.5 0H41v-5.5H25.5Zm0-8.5H41V25H25.5ZM7 22h34v-6H7Z"/>
        </svg>
    )
}