import { Link } from "react-router-dom";
import "./style.scss";
import Loading from "../../assets/images/jsx/Loading";
import { dayDate } from "../../utils";

export default function NewsComponent({ posts, loading }: any) {
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {posts.map((post: any, index: number) => (
        <div key={index}>
          <Link
            reloadDocument
            to={`/news/${post.not_numero}`}
            className="textLink"
          >
            <div className="box-news">
              <h3 className="news_title">{post.not_titulo}</h3>
              <h4 className="lead">{post.not_lead}</h4>
              <div className="box-info_news">
                <p>{dayDate(post.not_data, "llll")}</p>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </>
  );
}
