import api from "../../services";
import { getHeaders } from "../auth";

export default async function loadVideoFilter(marketPlan, setPosts) {
  await api
    .get(`/videos/mercado/${marketPlan}`, getHeaders())
    .then((res) => {
      setPosts(res.data);
    })
    .catch((e) => console.log("Erro ao carregar filtros:", e));
}
