import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom"

export default function TokenLogin() {
  const navigate = useNavigate()
  const { token } = useParams();

  useEffect(() => {
    handleTokenNavigate()
  }, [])

  function handleTokenNavigate(){
    if (token === '') return navigate('/login')
    else return navigate('/home')
  }

  return (
    <>
    </>
  )

}